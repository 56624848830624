import React from 'react'
import Layout from 'src/layout'
import Hero from 'src/components/Hero'
import GradientOverlay from 'src/components/GradientOverlay'
import FlexibleLogo from 'src/components/FlexibleLogo'
import HeroContent from 'src/components/HeroContent'
import Typography from 'src/components/Typography'
import Button from 'src/components/Button'
import Section from 'src/components/Section'
import Container from 'src/components/Container'
import FullTeaser from 'src/components/FullTeaser'
import { graphql } from 'gatsby'
import ProjectList from 'src/components/ProjectList'
import FocusPoints from 'src/components/FocusPoints'
import ProjectsSlider from 'src/components/ProjectsSlider'
import _ from 'lodash'

const Projects = props => {
  const {
    data: {
      focusTeaser: { nodes: focusTeaser },
      projects: { nodes: projects },
      focuses: { nodes: focuses },
      pages: { nodes: pages },
    },
  } = props

  const {
    featured,
    metaTitle,
    metaDescription,
    metaImage,
    contactTitle,
    contactText,
    contact,
  } = pages[0]

  const metaProps = {
    metaTitle,
    metaDescription,
    metaImage,
  }

  const contactProps = {
    contactTitle,
    contactText,
    contact,
  }

  return (
    <Layout meta={metaProps} contact={contactProps}>
      {/*
            Hero
      */}
      <Section border={false} flush>
        <Hero>
          <GradientOverlay color="bottomLeft" />
          <FlexibleLogo color="primary" size={80} />
          <HeroContent>
            <Typography variant="heroLabel" paragraph>
              Projekte
            </Typography>
            <Typography variant="hero" paragraph>
              Lernen Sie ganzheitliche Architektur kennen — 278 Mal
            </Typography>
          </HeroContent>
        </Hero>
      </Section>

      {/*
            Projects
      */}
      {featured && featured.length > 0 && (
        <Section color="secondary" border={false} flush>
          <Container>
            <Typography variant="h1" paragraph>
              Laufende Projekte
            </Typography>
            <ProjectsSlider projects={featured} />
          </Container>
        </Section>
      )}

      {/*
            Schwerpunkte
      */}
      <Section>
        <Container>
          <Typography variant="h1" paragraph color="primary">
            Schwerpunkte
          </Typography>
          <FocusPoints focuses={focuses} />
        </Container>
      </Section>

      {/*
            Full Teaser Projects
      */}
      {focusTeaser[0].cover && focusTeaser[0].cover.local && (
        <Section border={false} flush>
          <FullTeaser
            imgFluid={focusTeaser[0].cover.local.childImageSharp.fluid}
          >
            <Typography variant="fullTeaser" paragraph>
              Wir machen auch Sachen, die in keine Schublade passen. Neugierig?
            </Typography>
            <Typography variant="body" paragraph>
              Abseits unserer Schwerpunkte haben wir noch mehr zu bieten.
              Entdecken Sie unsere etwas anderen Projekte.
            </Typography>
            <Button color="white" to="/schwerpunkte/ohne-schublade">
              Projekte ansehen
            </Button>
          </FullTeaser>
        </Section>
      )}

      {/*
            Project Table
      */}
      <Section color="secondary" flush border={false}>
        <Container>
          <Typography variant="h2" paragraph>
            Alle Projekte
          </Typography>
          <ProjectList
            {...{
              projects,
              filterOptions: {
                year: _.uniq(projects.map(({ year: x }) => x)),
                focus: _.uniq(
                  _.flatten(projects.map(({ focus: x }) => x.title))
                ).filter(x => x !== 'Kein Schwerpunkt'),
                tags: _.uniq(
                  _.flatten(
                    projects.map(({ tags: x }) => x.map(tag => tag.value))
                  )
                ),
              },
            }}
          />
        </Container>
      </Section>
    </Layout>
  )
}

export default Projects

export const query = graphql`
  query {
    pages: allPages(filter: { id__normalized: { eq: "projekte" } }) {
      nodes {
        contactTitle
        contactText
        contact {
          ...ContactFragment
        }
        featured {
          title
          slug
          intro
          createPage
          cover {
            local {
              childImageSharp {
                fluid(maxWidth: 1280, quality: 60) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        metaTitle
        metaDescription
        metaImage {
          local {
            childImageSharp {
              fixed(width: 1024, quality: 60) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }

    projects: allProjects(
      filter: { id: { ne: "dummy" } }
      sort: { order: DESC, fields: completed_at }
    ) {
      nodes {
        ...ProjectListFragment
      }
    }

    focuses: allFocuses(
      filter: {
        slug: { nin: ["ohne-schublade", "kein-schwerpunkt"] }
        id: { ne: "dummy" }
      }
    ) {
      nodes {
        ...FocusPointsFragment
      }
    }

    focusTeaser: allFocuses(filter: { slug: { eq: "ohne-schublade" } }) {
      nodes {
        cover {
          local {
            childImageSharp {
              fluid(maxWidth: 800, quality: 60) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`
