import React from 'react'
import styled from 'styled-components'
import Grid from 'src/components/Grid'
import { Box } from '@material-ui/core'
import Typography from 'src/components/Typography'
import Image from 'src/components/Image'
import Button from 'src/components/Button'
import { graphql } from 'gatsby'

const Styled = styled.div`
  margin: 20px 20px 0;
`

const StyledHeadline = styled(Typography)`
  margin-bottom: 8px;
`

const FocusPoints = props => {
  const { passProps, focuses } = props

  return (
    <div {...passProps}>
      <Grid variant="2x">
        {focuses.map((entry, key) => (
          <div key={key}>
            {entry.cover && entry.cover.local && (
              <Image fluid={entry.cover.local.childImageSharp.fluid} />
            )}
            <Styled>
              <StyledHeadline variant="h2">{entry.title}</StyledHeadline>
              <Typography variant="body" paragraph>
                {entry.intro}
              </Typography>
              <Button to={'/schwerpunkte/' + entry.slug}>
                Projekte ansehen
              </Button>
            </Styled>
          </div>
        ))}
      </Grid>
    </div>
  )
}

FocusPoints.propTypes = {}

FocusPoints.defaultProps = {}

export default FocusPoints

export const query = graphql`
  fragment FocusPointsFragment on focuses {
    title
    intro
    slug
    cover {
      local {
        childImageSharp {
          fluid(maxWidth: 490, quality: 60) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`
