import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import GradientOverlay from '../GradientOverlay/GradientOverlay'
import BackgroundImage from '../BackgroundImage/BackgroundImage'
import Container from '../Container/Container'
import HeroContent from '../HeroContent/HeroContent'
import { up } from 'styled-breakpoints'

const Styled = styled.div`
  position: relative;
  color: white;
`

const Background = styled.div`
  height: 100%;
  display: flex;
  justify-content: stretch;
  width: 100%;
  position: absolute;
  background: #666;
`

const Gradient = styled(GradientOverlay)`
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  position: relative;
  flex: 2 0;
`
const Image = styled.div`
  position: relative;
  /* flex: 1 1; */
  display: none;

  @media (min-width: 980px) {
    display: block;
    flex: 1 1;
    max-width: 540px;
  }
`

const Wrapper = styled(Container)`
  position: relative;
  padding-top: ${({ theme }) => theme.spacing(12.5, 'px')};
  padding-bottom: ${({ theme }) => theme.spacing(8.75, 'px')};
`

const Content = styled.div`
  max-width: 600px;
  padding-right: ${({ theme }) => theme.spacing(5, 'px')};
`

const FullTeaser = props => {
  const { className, children, imgFluid } = props

  return (
    <Styled {...{ className }}>
      <Background>
        <Gradient color="fullTeaser" />
        {imgFluid && (
          <Image>
            <BackgroundImage {...{ fluid: imgFluid }} />
          </Image>
        )}
      </Background>
      <Wrapper>
        <Content>{children}</Content>
      </Wrapper>
    </Styled>
  )
}

FullTeaser.propTypes = {
  imgFluid: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  children: PropTypes.node,
  className: PropTypes.string,
}

FullTeaser.defaultProps = {
  //className: 'class'
}

export default FullTeaser
