import React from 'react'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'
import { Box } from '@material-ui/core'
import Slider from 'src/components/Slider'
import Typography from 'src/components/Typography'
import Button from 'src/components/Button'
import Image from 'src/components/Image'
import ProjectLinkButton from 'src/components/ProjectLinkButton'
import get from 'lodash/get'
import PropTypes from 'prop-types'

const StyledLink = styled(Link)`
  color: currentColor;
  text-decoration: none;
`

function getCover(entry) {
  let cover = get(entry, 'cover.childImageSharp.fluid', null)

  if (!cover) {
    cover = get(entry, 'cover.local.childImageSharp.fluid', null)
  }

  return cover
}
/* Randomize array in-place using Durstenfeld shuffle algorithm */
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

const ProjectsSlider = props => {
  const { projects, randomize } = props

  const shuffledProjects = randomize ? shuffleArray(projects) : projects

  return (
    <div>
      <Slider slidesToShow={3} slidesToScroll={3}>
        {shuffledProjects.map((entry, key) => (
          <Box key={key}>
            {entry.cover && entry.cover.local && (
              <Link to={'/projekte/' + entry.slug}>
                <Image fluid={{ ...getCover(entry), aspectRatio: 1.5 }} />
              </Link>
            )}
            <Box mt={20 / 8} mx={20 / 8}>
              <StyledLink to={'/projekte/' + entry.slug}>
                <Typography variant="projectTileHeader">
                  {entry.title}
                </Typography>
                <Typography variant="projectTileTeaser" paragraph>
                  {entry.intro}
                </Typography>
              </StyledLink>
              <ProjectLinkButton entry={entry} />
            </Box>
          </Box>
        ))}
      </Slider>
    </div>
  )
}

ProjectsSlider.propTypes = {
  randomize: PropTypes.bool,
}

ProjectsSlider.defaultProps = {
  randomize: false,
}

export default ProjectsSlider

export const query = graphql`
  fragment ProjectSliderFragment on projects {
    title
    slug
    intro
    createPage
    cover {
      local {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 60) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`
